import styles from "./styles.module.scss"
import Link from "next/link"
import Image from "next/image"
import { motion } from "framer-motion"
import { useRouter } from "next/router"
import { ModalLogin } from "../ModalLogin"
//import { SelectHeader } from '../SelectHeader'
import { useSubCore } from "@/hooks/useSubCore"
import { RxHamburgerMenu } from "react-icons/rx"
import { useCore } from "@/core-nextv3/core/core"
import {
    CART_BET_SETTING,
    LIVE_LEVEL_SETTING,
    MESSAGE_SETTING,
    SPORT_LEVEL_SETTING,
    THEME_SETTING,
} from "@/setting/settings"
import br from "../../../public/assets/img/br.png"
import kr from "../../../public/assets/img/kr.png"
import { AuthContext } from "../context/AuthContext"
import logo from "../../../public/assets/img/logo.png"
import { useContext, useEffect, useRef, useState } from "react"
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai"
import { BsArrowRightShort } from "react-icons/bs"
import {
    clearCart,
    delBetBonusCart,
    useGetBetCart,
} from "@/core-nextv3/bet/bet.api"
import { dispatchEvent, useOnEvent } from "@/core-nextv3/util/use.event"
import { PopupsModal } from "../PopupsModal"
import { NotificationPopup } from "../NotificationPopup"
import {
    collectionDocument,
    countDocument,
    getDocument,
} from "@/core-nextv3/document/document.api"
import { calls } from "@/core-nextv3/util/call.api"
import { customImageLoader, getAccid } from "@/core-nextv3/util/util"
import { useTranslation } from "next-i18next"
import { ModalTimeLive } from "../ModalTimeLive"
import { CiLogin } from "react-icons/ci"
import { FaUser } from "react-icons/fa"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { FirebaseContext } from "../context/FirebaseContext"
import redirectTo from "@/bet-nextv1/utils/redirectTo"
// import { header_submenu_animation } from '@/animation'

const lang = [
    // { id: 1, name: "En" },
    { id : 2, name : "PT", flag : br },
    { id : 3, name : "KR", flag : kr },
]

export const Header: React.FC<any> = ({
    languages,
    fixtures,
    sports,
    locations,
    banks,
    // popups,
    sportLevels,
    liveLevels,
    mgmLevels,
    generalConfigs,
    dividend,
    account
}:any) => 
{
    const router                                = useRouter()
    const pagePath                              = useRouter().asPath
    const [ openSubMenu, setOpneSubMenu ]       = useState(false)
    const [ disableClose, setDisableClose ]     = useState(false)
    const [ popupsToShow, setPopupsToShow ]     = useState<any>([])
    const [ openPopups, setOpenPopups ]         = useState(false)
    const [ messageCounter, setMessageCounter ] = useState<any>(0)

    const [ messageCounterAuto, setMessageCounterAuto ]         = useState<any>(0)
    const [ messageCounterAnswered, setMessageCounterAnswered ] = useState<any>(0)

    const firebaseApp = useContext(FirebaseContext)

    const clickRef: any = useRef(null)

    const { awaitLoadingHTTP } = useCore()
    const { setSelectedSport } = useSubCore()

    const { user, logout, setIsLogged, loading } = useContext(AuthContext)
    // console.warn(user)

    const { t } = useTranslation()

    const {
        setGames,
        setSports,
        setLocations,
        openLoginModal,
        setOpenLoginModal,
        typeLogin,
        setTypeLogin,
        setReloadGames,
        setSelectedLocation,
        setDividends,
        popups,
        setSelectedGame,
    } = useSubCore() 

    const { selectedLanguage, setBetCart, setAwaitLoadingHTTP }          = useCore()
    const { setSelectedBet, setSelectedIndex, setOpenAdditionalOptions } =
    useSubCore()

    const { setSelectedLeague } = useSubCore()

    const mainRoute = THEME_SETTING.redirectType === "home" ? "/" : "/home/"

    // useGetBetCart(CART_BET_SETTING, (data: any) => {
    //   dispatchEvent('changeCart', data)
    // })

    // useOnEvent('changeCart', (data: any) => {
    //   setBetCart(data)
    // })

    useEffect(() => 
    {
        if (THEME_SETTING.redirectType !== "loginPage") 
        {
            if (router.asPath !== mainRoute) 
            {
                setDisableClose(true)
            }
            else 
            {
                setDisableClose(false)
                setOpenLoginModal(false)
            }
        }
    }, [ router.asPath ])

    // console.warn('DIVIDENDS', dividends)

    // console.warn('livelevels', liveLevels)

    useEffect(() => 
    {
        if (fixtures?.length > 0) 
        {
            setGames(fixtures)
        }

        if (sports?.length > 0) 
        {
            setSports(sports)
        }

        if (locations?.length > 0) 
        {
            setLocations(locations)
        }
    }, [ fixtures, sports, locations ])

    const checkMessages = (messages: any) => 
    {
    // console.warn('messages', messages)
        let count = 0

        for (let i = 0; i < messages?.length; i++) 
        {
            if (
                messages[i]?.views === 0 &&
        messages[i]?.answer &&
        messages[i]?.answer !== ""
            ) 
            {
                count += 1
            }
        }

        return count
    }

    useEffect(() => 
    {
        if (user) 
        {
            // notificação automatica\
            // console.warn('user', user?.referencePath)
            const whereCollection = [
                where("client", "==", user?.docRef),
                where("views", "==", 0),
                where("_archive", "==", false),
            ]
            const querySport      = query(
                collection(firebaseApp.firestore, `/${getAccid()}/message/documents`),
                ...whereCollection
            )

            const unsubscribe = onSnapshot(querySport, async (querySnapshot: any) => 
            {
                const docs = querySnapshot.docs.map((doc: any) => doc.data())
                // console.warn('docs unsubscribe', docs)
                const newCounterPerView = checkMessages(docs)

                setMessageCounterAuto(newCounterPerView)
                // setMessageCounter(messageCounter + newCounterPerView)
            })

            // mensagem reposndida do admin
            const whereCollection2 = [
                where("client", "==", user?.docRef),
                where("messageViewed", "==", true),
                where("answerViewed", "==", false),
                where("_archive", "==", false),
            ]
            const querySport2      = query(
                collection(firebaseApp.firestore, `/${getAccid()}/message/documents`),
                ...whereCollection2
            )

            const unsubscribe2 = onSnapshot(
                querySport2,
                async (querySnapshot: any) => 
                {
                    const docs = querySnapshot.docs.map((doc: any) => doc.data())
                    // console.warn("docs unsubscribe 2", docs)
                    setMessageCounterAnswered(docs.length)
                    // setMessageCounter(messageCounter + docs.length)
                }
            )

            return () => 
            {
                unsubscribe()
                unsubscribe2()
            }
        }
    }, [ user ])

    // console.warn('messageCounter', messageCounter)

    // console.warn('messageCounterAuto', messageCounterAuto)
    // console.warn('messageCOunterAnswered', messageCounterAnswered)

    // useEffect(() => {
    //   const fetchMessages = async () => {
    //     setMessageCounter(0)
    //     const [counterPerView, counterPerMessageViewed] = await calls(
    //       // notificação automatica
    //       collectionDocument(
    //         MESSAGE_SETTING.merge({
    //           where: [
    //             {
    //               field: 'client',
    //               operator: '==',
    //               value: { referencePath: user?.referencePath },
    //             },
    //             {
    //               field: 'views',
    //               operator: '==',
    //               value: 0,
    //             },
    //             {
    //               field: '_archive',
    //               operator: '==',
    //               value: false,
    //             },
    //           ],
    //         })
    //       ),

    //       // mensagem reposndida do admin
    //       collectionDocument(
    //         MESSAGE_SETTING.merge({
    //           where: [
    //             {
    //               field: 'client',
    //               operator: '==',
    //               value: { referencePath: user?.referencePath },
    //             },
    //             {
    //               field: 'messageViewed',
    //               operator: '==',
    //               value: true,
    //             },
    //             {
    //               field: 'answerViewed',
    //               operator: '==',
    //               value: false,
    //             },
    //             {
    //               field: '_archive',
    //               operator: '==',
    //               value: false,
    //             },
    //           ],
    //         })
    //       )
    //     )

    //     // console.warn('counterPerView', counterPerView?.count)
    //     // console.warn('counterPerMessageViewed', counterPerMessageViewed?.count)

    //     if (counterPerView?.status && counterPerMessageViewed?.status) {
    //       const newCounterPerView = checkMessages(counterPerView?.collection)
    //       // console.warn('gambiarra', newCounterPerView)

    //       const counter = newCounterPerView + counterPerMessageViewed?.count
    //       setMessageCounter(counter)
    //     }
    //   }

    //   fetchMessages()
    // }, [user, router.asPath])

    useEffect(() => 
    {
        const path = THEME_SETTING.redirectType === "home" ? "/" : "/home/"
        console.warn("aqui", popups, path)

        if (router.asPath === path && !user) 
        {
            const memo = []

            for (let i = 0; i < popups?.length; i++) 
            {
                if (popups[i]?.showBeforeLogin === true && popups[i]?.active === true) 
                {
                    memo.push(popups[i])
                }
            }

            setPopupsToShow(memo)
            setOpenPopups(true)
        }
        else if (router.asPath === path && user) 
        {
            const memo = []

            for (let i = 0; i < popups?.length; i++) 
            {
                if (
                    popups[i]?.showBeforeLogin === false &&
          popups[i]?.active === true
                ) 
                {
                    memo.push(popups[i])
                }
            }

            setPopupsToShow(memo)
            setOpenPopups(true)
        }
    }, [ user, popups ])

    useEffect(() => 
    {
        const handleOutSideClick = (event: any) => 
        {
            if (!clickRef?.current?.contains(event.target)) 
            {
                setOpneSubMenu(false)
            }
        }

        window.addEventListener("mousedown", handleOutSideClick)

        return () => 
        {
            window.removeEventListener("mousedown", handleOutSideClick)
        }
    }, [ clickRef ])

    const handleLogoutClick = async () => 
    {
        setAwaitLoadingHTTP(true)
        setIsLogged(false)
        // await calls(
        await logout()
        // const result = await clearCart(CART_BET_SETTING)
        // await delBetBonusCart(CART_BET_SETTING)
        // )

        // setBetCart(result?.data)
        setSelectedBet(null)
        setSelectedIndex(null)
        setOpenAdditionalOptions(false)
        // router.push('/')
        setAwaitLoadingHTTP(false)
    }

    const handleSportsClick = () => 
    {
        setSelectedLeague(null)
        setSelectedSport(null)
        setSelectedLocation(null)
        setSelectedGame(null)
        // if (router.asPath === '/sports/') {
        setReloadGames(true)
        redirectTo("/sports/", user, router, setOpenLoginModal)
    // }
    }

    const checkNotifications = () => 
    {
        const userCheck: any = user
        // console.warn('USERCHECK', userCheck)
        const route: any = router.asPath

        if (route === "/deposit/?tabIndex=4" || route === "/deposit/?tabIndex=3") 
        {
            return false
        }
        else 
        {
            if (userCheck?.views_advicesUsers > 0) 
            {
                console.log("views_advicesUsers")
                return true
            }
            else if (messageCounterAuto + messageCounterAnswered > 0) 
            {
                console.log("messageCounter")
                return true
            }

            return false
        }
    }

    const header_submenu_animation = {
        animate : {
            height     : "0",
            overflow   : "hidden",
            display    : "flex",
            transition : {
                type     : "tween",
                duration : 0.35,
                ease     : "easeInOut",
            },
        },

        hover : {
            height     : "auto",
            display    : "flex",
            transition : {
                type     : "tween",
                duration : 0.35,
                ease     : "easeInOut",
            },
        },
    }

    // console.warn("AQUI", loading, awaitLoadingHTTP)

    return (
        <>
            <header className={styles.header}>
                <div className={styles.content}>
                    <div className={styles.menuLeft}>
                        <div className={styles.logoMenu}>
                            <Link href='/' className='logo'>
                                <img
                                    src={account?.logo?._url || logo}
                                    alt='logo'
                                    // loader={customImageLoader} 
                                />
                            </Link>
                        </div>

                        <ul className={styles.mainMenu}>
                            <li
                                className={styles.menuItemGrid}
                                // initial='animate'
                                // animate='animate'
                                // whileHover='hover'
                            >
                                <AiOutlineMenu />

                                <ul
                                    className={styles.subMenuGrid}
                                    // variants={header_submenu_animation}
                                >
                                    <div className={styles.grid}>
                                        <div className={styles.subMenuGridItem}>
                                            <img src='/assets/img/deposito.png' alt='' />
                                            <a
                                                className={styles.title}
                                                onClick={() => redirectTo("/deposit?tabIndex=0", user, router, setOpenLoginModal)}
                                            >
                                                {t("Depósito")} <BsArrowRightShort />
                                            </a>
                                        </div>

                                        <div className={styles.subMenuGridItem}>
                                            <img src='/assets/img/payout.png' alt='' />
                                            <a
                                                className={styles.title}
                                                onClick={() => redirectTo("/deposit?tabIndex=1", user, router, setOpenLoginModal)}
                                            >
                                                {t("Saque")} <BsArrowRightShort />
                                            </a>
                                        </div>

                                        <div className={styles.subMenuGridItem}>
                                            <img src='/assets/img/movimentacao.png' alt='' />
                                            <a
                                                className={styles.title}
                                                onClick={() => redirectTo("/deposit?tabIndex=2", user, router, setOpenLoginModal)}
                                            >
                                                {t("Movimentação")}
                                                <BsArrowRightShort />
                                            </a>
                                        </div>

                                        <div className={styles.subMenuGridItem}>
                                            <img src='/assets/img/customer.png' alt='' />
                                            <a
                                                className={styles.title}
                                                onClick={() => redirectTo("/deposit?tabIndex=3", user, router, setOpenLoginModal)}
                                            >
                                                {t("Perguntas")} <BsArrowRightShort />
                                            </a>
                                        </div>

                                        <div className={styles.subMenuGridItem}>
                                            <img src='/assets/img/note.png' alt='' />
                                            <a
                                                className={styles.title}
                                                onClick={() => redirectTo("/deposit?tabIndex=4", user, router, setOpenLoginModal)}
                                            >
                                                {t("Caixa de Notificações")} <BsArrowRightShort />
                                            </a>
                                        </div>

                                        <div className={styles.subMenuGridItem}>
                                            <img src='/assets/img/event.png' alt='' />
                                            <a
                                                className={styles.title}
                                                onClick={() => redirectTo("/event", user, router, setOpenLoginModal)}
                                            >
                                                {t("Evento")} <BsArrowRightShort />
                                            </a>
                                        </div>

                                        {/* <div className={styles.subMenuGridItem}>
                      <img src='/assets/img/coupon.png' alt='' />
                      <a
                        className={styles.title}
                        onClick={() => redirectTo('/deposit?tabIndex=3')}
                      >
                        {t('Cupom / Pontos')} <BsArrowRightShort />
                      </a>
                    </div> */}

                                        {/* <div className={styles.subMenuGridItem}>
                      <img src='/assets/img/attendance.png' alt='' />
                      <a
                        className={styles.title}
                        onClick={() => redirectTo('/')}
                      >
                        {t('CheckIn')} <BsArrowRightShort />
                      </a>
                    </div> */}

                                        {/* <div className={styles.subMenuGridItem}>
                      <img src='/assets/img/friend.png' alt='' />
                      <a
                        className={styles.title}
                        onClick={() => redirectTo('/friend')}
                      >
                        {t('INDICAÇÃO')} <BsArrowRightShort />
                      </a>
                    </div> */}

                                        <div className={styles.subMenuGridItem}>
                                            <img src='/assets/img/notice.png' alt='' />
                                            <a
                                                className={styles.title}
                                                onClick={() => redirectTo("/deposit?tabIndex=7", user, router, setOpenLoginModal)}
                                            >
                                                {t("Avisos / Regras")} <BsArrowRightShort />
                                            </a>
                                        </div>

                                        <div className={styles.subMenuGridItem}>
                                            <img src='/assets/img/betting-history.png' alt='' />
                                            <a
                                                className={styles.title}
                                                onClick={() => redirectTo("/deposit?tabIndex=5", user, router, setOpenLoginModal)}
                                            >
                                                {t("Histórico de Apostas")} <BsArrowRightShort />
                                            </a>
                                        </div>

                                        <div className={styles.subMenuGridItem}>
                                            <img src='/assets/img/cash-history.png' alt='' />
                                            <a
                                                className={styles.title}
                                                onClick={() => redirectTo("/deposit?tabIndex=6", user, router, setOpenLoginModal)}
                                            >
                                                {t("Histórico de Cash")} <BsArrowRightShort />
                                            </a>
                                        </div>

                                        {user?.isAdmin && (
                                            <div className={styles.subMenuGridItem}>
                                                <img src='/assets/img/attendance.png' alt='' />
                                                <a
                                                    className={styles.title}
                                                    onClick={() => redirectTo("/checking", user, router, setOpenLoginModal)}
                                                >
                                                    {t("CheckIn")} <BsArrowRightShort />
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.menuLinks}>
                        {/* {sportLevels?.status && ( */}
                        <div className={router.asPath == "/sports/" ? styles.active : ""}>
                            <div className={styles.link} onClick={() => handleSportsClick()}>
                                <span>{t("Esportes")}</span>
                            </div>
                        </div>
                        {/* )} */}
                        {/* {liveLevels?.status && ( */}
                        <div
                            className={router.asPath == "/liveSports/" ? styles.active : ""}
                        >
                            <div className={styles.link} onClick={() => redirectTo("/liveSports/", user, router, setOpenLoginModal)}>
                                <span>{t("Live Sports")}</span>
                            </div>
                        </div>
                        {/*  )} */}
                        {/* {mgmLevels?.status && ( */}
                        <div>
                            <div className={styles.link} onClick={() => redirectTo("/mini/lotus/baccarat/1", user, router, setOpenLoginModal)}>
                                <span>{t("Mini Game")}</span>
                            </div>
                        </div>
                        {/* )} */}
                        {/* <li>
                <Link href='URL:void(0)'>
                  <span>{t('Jogo simulação')}</span>
                </Link>
              </li> */}
                        <div className={router.asPath == "/casino/" ? styles.active : ""}>
                            <div className={styles.link} onClick={() => redirectTo("/casino/", user, router, setOpenLoginModal)}>
                                <span>{t("Cassino")}</span>
                            </div>
                        </div>
                    </div>

                    {awaitLoadingHTTP === false && user && !loading ? (
                        <div className={styles.dashboarWrap}>
                            {/* <div className={styles.items + ' ' + styles.dText}>
                                <span className={styles.small}>{t("Your balance")}</span>
                                <h6>$9.22</h6>
                            </div>

                            <div className={styles.items + ' ' + styles.dCmn}>
                                <Link href="/deposit" className={styles.button}>
                                    <span>{t("Deposit")}</span>
                                </Link>
                            </div>

                            <div className={styles.items + ' ' + styles.dashboarSocial}>
                                <Link href="URL:void()" className={styles.icons}>
                                    <AiFillGift />
                                    <span className={styles.count}>2</span>
                                </Link>

                                <Link href="URL:void()" className={styles.icons}>
                                    <AiOutlineMessage />
                                    <span className={styles.count}>2</span>
                                </Link>

                                <div className={styles.customDropdown}>
                                    <div
                                        className={styles.customDropdownUser}
                                        data-set="custom-dropdown"
                                    >
                                        <Link href="/dashboard" className={styles.icons}>
                                            <BiUserCircle />
                                        </Link>
                                    </div>

                                    <div
                                        // className={`custom-dropdown__content ${isOpen && "is-open"}`}
                                        className={styles.customDropdownContent}
                                    >
                                        <div className={styles.customDropdownBody}>
                                            <ul className={styles.customDropdownList}>
                                                <li>
                                                    <Link
                                                        href="/dashboard"
                                                        className={styles.customDropdownBodyLink}
                                                    >
                                                        <span className={styles.customDropdownBodyIcon}>
                                                            <i className="fas fa-layer-group"></i>
                                                        </span>
                                                        <span className={styles.customDropdownBodyText}>
                                                            Dashboard
                                                        </span>
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link
                                                        href="URL:void()"
                                                        className={styles.customDropdownBodyLink}
                                                    >
                                                        <span className={styles.customDropdownBodyIcon}>
                                                            <i className="fas fa-cog"></i>
                                                        </span>
                                                        <span className={styles.customDropdownBodyText}>
                                                            Settings
                                                        </span>
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link
                                                        href="URL:void()"
                                                        className={styles.customDropdownBodyLink}
                                                    >
                                                        <span className={styles.customDropdownBodyIcon}>
                                                            <i className="fas fa-sign-out-alt"></i>
                                                        </span>
                                                        <span className={styles.customDropdownBodyText}>
                                                            Logout
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className={styles.languageWrap}>
                                <div className={styles.flag}>
                                    {
                                        selectedLanguage === 'pt' ?
                                            <Image src={br} alt="flag" loader={customImageLoader}/>
                                        :
                                        <Image src={kr} alt="flag" loader={customImageLoader}/>
                                    }
                                </div>
                                <SelectHeader data={lang} />
                            </div> */}
                            {/* {awaitLoadingHTTP === false && user?.isAdmin && (
                <div className={styles.languageWrap}>
                  <div className={styles.flag}>
                    {selectedLanguage === 'pt' ? (
                      <Image src={br} alt='flag' loader={customImageLoader} />
                    ) : (
                      <Image src={kr} alt='flag' loader={customImageLoader} />
                    )}
                  </div>
                  <SelectHeader data={lang} />
                </div>
              )} */}
                            <button onClick={() => handleLogoutClick()}>{t("Logout")}</button>
                        </div>
                    ) : (
                        <div className={styles.menuBtnGrp}>
                            {!loading && !awaitLoadingHTTP && (
                                <>
                                    <button
                                        // href="URL:void(0)"
                                        className={styles.buttonNavigation}
                                        data-bs-toggle='modal'
                                        data-bs-target='#signupin'
                                        onClick={() => (
                                            setOpenLoginModal(true),
                                            setTypeLogin("login"),
                                            setDisableClose(false)
                                        )}
                                    >
                                        <span>{t("Logar")}</span>
                                    </button>

                                    <button
                                        // href="URL:void(0)"
                                        className={styles.buttonNavigationTow}
                                        data-bs-toggle='modal'
                                        data-bs-target='#signupin'
                                        onClick={() => (
                                            setOpenLoginModal(true),
                                            setTypeLogin("register"),
                                            setDisableClose(false)
                                        )}
                                    >
                                        <span className='rela'>{t("Cadastrar")}</span>
                                    </button>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </header>

            <div className={styles.headerMobile}>
                <div className={styles.contentMobile}>
                    <div className={styles.logoMenu}>
                        <Link href='/' className={styles.logo}>
                            <img
                                src={account?.logo?._url || logo}
                                alt='logo'
                                // priority={true}
                                // loader={customImageLoader}
                            />
                        </Link>
                    </div>

                    <div className={styles.menuBtnMobile}>
                        <div className={styles.menuBtnGrp}>
                            <div className={styles.languageWrap}>
                                {/* {user ? 
                                    <div className={styles.dashboarWrap}>
                                        <div className={styles.items + ' ' + styles.dashboarSocial}>
                                            <Link href="URL:void()" className={styles.icons}>
                                                <AiFillGift />
                                                <span className={styles.count}>2</span>
                                            </Link>

                                            <Link href="URL:void()" className={styles.icons}>
                                                <AiOutlineMessage />
                                                <span className={styles.count}>2</span>
                                            </Link>

                                            <div className={styles.customDropdown}>
                                                <div
                                                    className={styles.customDropdownUser}
                                                    data-set="custom-dropdown"
                                                >
                                                    <Link href="URL:void()" className={styles.icons}>
                                                        <BiUserCircle />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : */}
                                {/* {awaitLoadingHTTP === false && user?.isAdmin && (
                  <>
                    <div className={styles.flag}>
                      {selectedLanguage === 'pt' ? (
                        <Image src={br} alt='flag' loader={customImageLoader} />
                      ) : (
                        <Image src={kr} alt='flag' loader={customImageLoader} />
                      )}
                    </div>
                    <SelectHeader data={lang} />
                  </>
                )} */}
                                {/* } */}
                            </div>

                            {openSubMenu === false ? (
                                <RxHamburgerMenu
                                    onClick={() => 
                                    {
                                        setOpneSubMenu(true)
                                    }}
                                />
                            ) : (
                                <AiOutlineClose onClick={() => setOpneSubMenu(false)} />
                            )}
                        </div>
                    </div>
                </div>

                {!user && !loading && !awaitLoadingHTTP && (
                    <div className={styles.loginHeaderButtons}>
                        <button
                            // href="URL:void(0)"
                            className={styles.buttonNavigation}
                            data-bs-toggle='modal'
                            data-bs-target='#signupin'
                            onClick={() => (
                                setOpenLoginModal(true),
                                setTypeLogin("login"),
                                setDisableClose(false)
                            )}
                        >
                            <CiLogin />
                            <span>{t("Logar")}</span>
                        </button>

                        <button
                            // href="URL:void(0)"
                            className={styles.buttonNavigationTow}
                            data-bs-toggle='modal'
                            data-bs-target='#signupin'
                            onClick={() => (
                                setOpenLoginModal(true),
                                setTypeLogin("register"),
                                setDisableClose(false)
                            )}
                        >
                            <FaUser />
                            <span className='rela'>{t("Cadastrar")}</span>
                        </button>
                    </div>
                )}
                {openSubMenu && (
                    <ul className={styles.mainMenuMobile} ref={clickRef}>
                        {/* {sportLevels?.status && ( */}
                        <li>
                            <div
                                // href='/sports'
                                className={styles.link}
                                onClick={() => 
                                {
                                    setSelectedSport(null);
                                    setReloadGames("true");
                                    redirectTo("/sports/", user, router, setOpenLoginModal)
                                }}
                            >
                                <span onClick={() => setOpneSubMenu(false)}>
                                    {t("Esportes")}
                                </span>
                            </div>
                        </li>
                        {/* )} */}
                        {/* {liveLevels?.status && ( */}
                        <li>
                            <div className={styles.link} onClick={() => redirectTo("/liveSports/", user, router, setOpenLoginModal)}>
                                <span onClick={() => setOpneSubMenu(false)}>
                                    {t("Live Sports")}
                                </span>
                            </div>
                        </li>
                        {/*  )} */}
                        {/* {mgmLevels?.status && ( */}
                        <li>
                            <div className={styles.link} onClick={() => redirectTo("/mini/lotus/baccarat/1", user, router, setOpenLoginModal)}>
                                <span onClick={() => setOpneSubMenu(false)}>
                                    {t("Mini Game")}
                                </span>
                            </div>
                        </li>
                        {/* )} */}
                        {/* <li>
              <Link href='URL:void(0)'>
                <span onClick={() => setOpneSubMenu(false)}>
                  {t('Jogo simulação')}
                </span>
              </Link>
            </li> */}
                        <li>
                            <div className={styles.link} onClick={() => redirectTo("/casino/", user, router, setOpenLoginModal)}>
                                <span onClick={() => setOpneSubMenu(false)}>
                                    {t("Cassino")}
                                </span>
                            </div>
                        </li>

                        {user !== null ? (
                            <div className={styles.dashboarWrap}>
                                <button
                                    onClick={() => (handleLogoutClick(), setOpneSubMenu(false))}
                                >
                                    {t("Logout")}
                                </button>
                            </div>
                        ) : (
                            <div className={styles.btns}>
                                <button
                                    // href="URL:void(0)"
                                    className={styles.buttonNavigation}
                                    data-bs-toggle='modal'
                                    data-bs-target='#signupin'
                                    onClick={() => (
                                        setOpenLoginModal(true),
                                        setTypeLogin("login"),
                                        setDisableClose(false),
                                        setOpneSubMenu(false)
                                    )}
                                >
                                    <span>{t("Logar")}</span>
                                </button>

                                <button
                                    // href="URL:void(0)"
                                    className={styles.buttonNavigationTow}
                                    data-bs-toggle='modal'
                                    data-bs-target='#signupin'
                                    onClick={() => (
                                        setOpenLoginModal(true),
                                        setTypeLogin("register"),
                                        setDisableClose(false),
                                        setOpneSubMenu(false)
                                    )}
                                >
                                    <span className='rela'>{t("Cadastrar")}</span>
                                </button>
                            </div>
                        )}
                    </ul>
                )}
            </div>
            {openLoginModal && (
                <ModalLogin
                    closeModal={setOpenLoginModal}
                    banks={banks}
                    disableClose={disableClose}
                    setDisableClose={setDisableClose}
                />
            )}

            {openPopups && (
                <PopupsModal
                    open={openPopups}
                    setOpen={setOpenPopups}
                    popups={popupsToShow}
                />
            )}

            {!openPopups && user && checkNotifications() && (
                <NotificationPopup
                    countMessages={messageCounterAuto + messageCounterAnswered}
                    notifications={user?.views_advicesUsers}
                />
            )}
        </>
    )
}
