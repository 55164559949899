import { translate } from "@/core-nextv3/util/useLanguage"
import { HeaderForMobileMenu } from "../HeaderForMobileMenu/index,"
import styles from "./styles.module.scss"
import router from "next/router"
import { useContext } from "react"
import { AuthContext } from "../context/AuthContext"
import { logoutAuth } from "@/core-nextv3/auth/auth.api"
import { AUTH_SETTING } from "@/setting/settings"
import { useTranslation } from "next-i18next"

export const AccountModal = ({ close, openModalBetslip }: any) => 
{
    const { user } = useContext<any>(AuthContext)
    const { t }    = useTranslation()

    const handleLogout = async () => 
    {
        await logoutAuth(AUTH_SETTING)

        router.reload()
    // toast.success(t("Logout feito com sucesso"))
    }

    return (
        <div className={styles.betSlipMobile}>
            <div className={styles.container}>
                {/* <div className={styles.iconClose}>
                        <AiOutlineClose onClick={() => close(false)} />
                    </div> */}
                <HeaderForMobileMenu closeFunction={close} />

                <div className={styles.content}>
                    {user && (
                        <div className={styles.informationUser}>
                            <div className={styles.top}>
                                <div className={styles.level}>
                                    <img src='/assets/img/image_level.png' alt='' />
                                    <p>{user?.level?.label}</p>
                                </div>

                                <div className={styles.user}>
                                    <div className={styles.information}>
                                        <span>{t("ID")}</span>
                                        <p className={styles.id}>{user?.code}</p>
                                    </div>

                                    <div className={styles.information}>
                                        <span>{t("Nickname")}</span>
                                        <p className={styles.days}>{user?.nickname}</p>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.divisor} />

                            <div className={styles.informationsMonney}>
                                <div className={styles.inputInformation}>
                                    <div className={styles.inputItem}>
                                        <img src='/assets/img/sportscash.png' alt='' />
                                        <label>{t("Dinheiro Esporte")}</label>
                                    </div>
                                    <span className={styles.value}>
                                        {user?.sport
                                            ? new Intl.NumberFormat("en").format(user?.sport)
                                            : 0}{" "}
                                        {t("Won")}
                                    </span>
                                </div>

                                <div className={styles.inputInformation}>
                                    <div className={styles.inputItem}>
                                        <img src='/assets/img/casinocash.png' alt='' />
                                        <label>{t("Dinheiro do Cassino")}</label>
                                    </div>
                                    <span className={styles.value}>
                                        {user?.casino
                                            ? new Intl.NumberFormat("en").format(user?.casino)
                                            : 0}{" "}
                                        {t("Won")}
                                    </span>
                                </div>
                            </div>

                            <div className={styles.buttons}>
                                <button
                                    onClick={() => 
                                    {
                                        router.push("/deposit?tabIndex=0")
                                        close(false)
                                    }}
                                >
                                    <img src='/assets/img/deposito.png' alt='' />
                                    {t("Depósito")}
                                </button>

                                <button
                                    onClick={() => 
                                    {
                                        router.push("/deposit?tabIndex=1")
                                        close(false)
                                    }}
                                >
                                    <img src='/assets/img/payout.png' alt='' />
                                    {t("Saque")}
                                </button>

                                <button
                                    onClick={() => 
                                    {
                                        router.push("/deposit?tabIndex=2")
                                        close(false)
                                    }}
                                >
                                    <img src='/assets/img/movimentacao.png' alt='' />
                                    {t("Movimentação")}
                                </button>

                                <button
                                    onClick={() => 
                                    {
                                        router.push("/deposit?tabIndex=4")
                                        close(false)
                                    }}
                                >
                                    <img src='/assets/img/customer.png' alt='' />
                                    {t("Perguntas")}
                                </button>

                                <button
                                    onClick={() => 
                                    {
                                        router.push("/deposit?tabIndex=5")
                                        close(false)
                                    }}
                                >
                                    <img src='/assets/img/note.png' alt='' />
                                    {t("Notificações")}
                                </button>

                                <button
                                    onClick={() => 
                                    {
                                        router.push("/event")
                                        close(false)
                                    }}
                                >
                                    <img src='/assets/img/event.png' alt='' />
                                    {t("Evento")}
                                </button>

                                <button
                                    onClick={() => 
                                    {
                                        router.push("/deposit?tabIndex=3")
                                        close(false)
                                    }}
                                >
                                    <img src='/assets/img/coupon.png' alt='' />
                                    {t("Cupom / Pontos")}
                                </button>

                                {/* <button
                  onClick={() => {
                    router.push('/')
                    close(false)
                  }}
                >
                  <img src='/assets/img/attendance.png' alt='' />
                  {t('CheckIn')}
                </button> */}

                                {/* <button
                  onClick={() => {
                    router.push('/friend')
                    close(false)
                  }}
                >
                  <img src='/assets/img/friend.png' alt='' />
                  {t('INDICAÇÃO')}
                </button> */}

                                <button
                                    onClick={() => 
                                    {
                                        router.push("/deposit?tabIndex=8")
                                        close(false)
                                    }}
                                >
                                    <img src='/assets/img/notice.png' alt='' />
                                    {t("Avisos / Regras")}
                                </button>

                                <button
                                    onClick={() => 
                                    {
                                        router.push("/deposit?tabIndex=6")
                                        close(false)
                                    }}
                                >
                                    <img src='/assets/img/betting-history.png' alt='' />
                                    {t("Histórico de Apostas")}
                                </button>

                                <button
                                    onClick={() => 
                                    {
                                        router.push("/deposit?tabIndex=7")
                                        close(false)
                                    }}
                                >
                                    <img src='/assets/img/cash-history.png' alt='' />
                                    {t("Histórico de Cash")}
                                </button>


                                <button onClick={() => handleLogout()}>
                                    <img src='/assets/img/logout.png' alt='' />
                                    {t("Logout")}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
