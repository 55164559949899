import styles from "./styles.module.scss"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { delItemCart } from "@/core-nextv3/bet/bet.api"
import { useCore } from "@/core-nextv3/core/core"
import { CART_BET_SETTING } from "@/setting/settings"
import { betNameParser } from "@/hooks/betNameParser"
import { useTranslation } from "next-i18next"
import { useContext, useEffect, useState } from "react"
import { GiConsoleController } from "react-icons/gi"
import CartModel from "@/model/cart.model"
import { AuthContext } from "../../context/AuthContext"

export const BetMinigameData = ({ item }: any) => 
{
    const { betCart, setBetCart, setAwaitLoadingHTTP, setIsLoadingCart } = useCore()
    const { t }                                                          = useTranslation()
    const [ reload, setReload ]                                          = useState()
    const { CART_BACKEND }                                               = useContext(AuthContext)
    // apenas p/ testes
    // const lock = true;
    useEffect(() => 
    {
        if (reload) 
        {
            setReload(false);
        }

    }, [ reload ])

    const removeCartItem = async (item: any) => 
    {
        if (CART_BACKEND) 
        {
            //Carrinho BackEnd
            setIsLoadingCart(true)
            const result = await delItemCart(CART_BET_SETTING.merge({ data : item }))
            setIsLoadingCart(false)

            if (result.status) 
            {
                setBetCart(null)
            }
        }
        else 
        {
            //Carrinho FrontEnd
            const cart = new CartModel(betCart);
            cart.delItem(item)
            setBetCart(cart)

        }
    }








    const parseLine = (line: any) => 
    {
    // Find the index of the "(" character
        const indexOfParenthesis = line.indexOf("(")

        // Check if the "(" character is found
        if (indexOfParenthesis !== -1) 
        {
            // Remove all characters after "(" using substring
            const newString = line.substring(0, indexOfParenthesis - 1)

            return newString
        }
        else 
        {
            return line
        }
    }

    function sequence(id: any) 
    {
        const hours   = Number(id.substring(8, 10))
        const minutes = Number(id.substring(10, 12))
        return hours * 60 + minutes + 1
    }

    return (
        <div className={styles.betItemData}>
            <div className={styles.content}>
                <div className={styles.leagueData}>
                    <span className={styles.league}>
                        {t(item?.providerName)} {t(item?.minigame?.name)}
                    </span>
                    <div className={styles.buttonContainer}>
                        <AiOutlineCloseCircle
                            onClick={() => 
                            {
                                removeCartItem(item)
                            }}
                            className={styles.closeButton}
                        />
                    </div>
                </div>
                <span className={styles.game}>
                    {t(item?.providerName)} {t(item?.minigame?.name)} -{" "}
                    {item?.game?.id.split("-").slice(-1).join("")} {t("Rodada")}
                </span>
                <div className={styles.marketData}>
                    <span className={styles.market}>
                        {item.bet && t(betNameParser(item.bet))}
                    </span>
                    <span className={styles.price}>
                        {(Math.round(item.price * 100) / 100).toFixed(2)}
                    </span>
                </div>
            </div>
            <div className={styles.divisor} />
            {item?.game?.active === false && (
            // lock === true &&(
                <div className={styles.lockItem}>
                    <div className={styles.image}>
                        <img alt='' src='/assets/img/Lock1.png' />
                    </div>
                </div>
            )}
        </div>
    )
}
