export default function redirectTo(path:any, user:any, router:any, setOpenLoginModal:any)
{
    if (user)
    {
        router?.push(path)
        return
    }
    else 
    {
        setOpenLoginModal(true)
        return
    }
}