import { create } from "zustand";
import { useTranslation } from 'next-i18next'

type UseCoreProps = {
    awaitLoadingHTTP     : boolean;
    isLoadingCart        : boolean; // SE O CARRINHO FOI CARREGADO
    isLoadingUser        : boolean; // SE O USUARIO  FOI CARREGADO
    user                 : any;
    cart                 : any;
    betCart              : any;
    colorTable           : any;
    cnpjWs               : any;
    pouchDb              : any;
    setColorTable        : any;
    setCart              : any;
    setBetCart           : any;
    setUser              : any;
    setCnpjWs            : any;
    setPouchDb           : any;
    setIsLoadingCart     : any;
    setIsLoadingUser     : any;
    setAwaitLoadingHTTP  : any;
    selectedLanguage     : any;
    setSelectedLanguage  : any;
    isOpenCart           : boolean;
    setIsOpenCart        : any;
    installmentRule      : any;
    setInstallmentRule   : any;
    isCloseMenuMobile    : boolean;
    setIsCloseMenuMobile : any;
};

export const useCore = create<UseCoreProps>((set:any, get:any) => 
({
    awaitLoadingHTTP     : false,
    isLoadingCart        : false,
    isLoadingUser        : false,
    isOpenCart           : false,
    isCloseMenuMobile    : false,
    user                 : null,
    cart                 : null,
    betCart              : null,
    installmentRule      : null,
    cnpjWs               : null,
    pouchDb              : null,
    colorTable           : null,
    freeShipping         : 0,
    selectedLanguage     : null,    
    // languages            : {},    
    setInstallmentRule   : (installmentRule:any) => set((state:any) => ({ installmentRule : installmentRule })),
    setCart              : (cart:any)            => set((state:any) => ({ cart : cart })),
    setBetCart           : (betCart:any)         => set((state:any) => ({ betCart : betCart })),
    setUser              : (user:any)            => set((state:any) => ({ user : user })),
    setColorTable        : (colorTable:any)      => set((state:any) => ({ colorTable : colorTable })),
    setCnpjWs            : (value:any)           => set((state:any) => ({ cnpjWs: value })),
    setPouchDb           : (value:any)           => set((state:any) => ({ pouchDb: value })),
    setIsLoadingCart     : (value:any)           => set((state:any) => ({ isLoadingCart : value })),
    setIsLoadingUser     : (value:any)           => set((state:any) => ({ isLoadingUser : value })),
    setIsOpenCart        : (value:any)           => set((state:any) => ({ isOpenCart : value })),
    setIsCloseMenuMobile : (value:any)           => set((state:any) => ({ isOpenCart : value })),
    setAwaitLoadingHTTP  : (value:any)           => set((state:any) => ({ awaitLoadingHTTP : value })),
    setSelectedLanguage  : (value:any)           => set((state:any) => ({ selectedLanguage : value })),   
}));