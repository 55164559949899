import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Dialog } from "@mui/material"
import { useCookies } from "react-cookie";
import { Popup } from "./popup";
import { useMediaQuery } from "react-responsive";


export const PopupsModal: React.FC<any> = ({
    open,
    setOpen,
    popups
}) => {
    // console.log(popups)
    const [activePopups, setActivePopups] = useState<any>([]);
    const [cookies, setCookies] = useCookies();
    const isSingleColum = useMediaQuery({ query: `(max-width: 1150px)` });

    // console.log(cookies)

    useEffect(() => {
        const memoPopups = [];
        for (let i = 0; i < popups?.length; i++) {
            if (!cookies[`${popups[i]?.id}`]) {
                memoPopups.push(popups[i]);
            }
        }

        if (memoPopups?.length === 0) {
            setOpen(false);
        }

        setActivePopups(memoPopups);
    }, [popups])

    const handleClosePopup = (popup: any) => {

        // const arr = [];
        // for(let i = 0; i < activePopups?.length; i++){
        //     if(activePopups[i]?.referencePath !== popup?.referencePath){
        //         arr.push(activePopups[i]);
        //     }
        // }
        // if(arr?.length > 0){
        //     setActivePopups(arr);
        // }
        // else{
        //     setOpen(false);
        // }

        let arr = activePopups;

        const indexToRemove = arr.findIndex((e: any) => e.referencePath === popup?.referencePath);


        if (isSingleColum) {
            arr.push(false);
            arr.shift();

            if (arr.every(item => item === false)) {

                setOpen(false);

            } else {
                setActivePopups(arr);
            }

            return
        }

        if (arr.length <= 4) {

            arr = arr.toSpliced(indexToRemove, 0, false);
            arr = arr.toSpliced(indexToRemove + 1, 1);

            if (arr.every(item => item === false)) {

                setOpen(false);

            } else {
                setActivePopups(arr);
            }

            return
        }
        /*
        let itemToAdd;    
        if(indexToRemove >= 0){
            itemToAdd = arr[indexToRemove + 4]
            arr = arr.toSpliced(indexToRemove + 4, 0, false);        
            arr = arr.toSpliced(indexToRemove + 5, 1);
        }

        if(itemToAdd){
             arr = arr.toSpliced(indexToRemove, 0, itemToAdd);
             arr = arr.toSpliced(indexToRemove + 1, 1);
        } else {
            arr = arr.toSpliced(indexToRemove, 0, false);        
            arr = arr.toSpliced(indexToRemove + 1, 1);
        }
        
        */

        if (indexToRemove >= 0) {

            for (let i = indexToRemove; i < arr.length; i = i + 4) {


                if (arr[i] !== false) {
                    arr = arr.toSpliced(i, 0, arr[i + 4] ?? false);
                    arr = arr.toSpliced(i + 1, 1);
                } else {
                    arr = arr.toSpliced(indexToRemove, 0, false);
                    arr = arr.toSpliced(indexToRemove + 1, 1);

                }

            }


        }


        if (arr.every(item => item === false)) {

            setOpen(false);

        } else {
            setActivePopups(arr);
        }


    }

    return (
        <Dialog
            open={open}
            onClose={setOpen}
            className={styles.popupsModal}
        >
            <div className={styles.content}>
                {activePopups?.slice(0, 4).map((popup: any, key: any) => (
                    popup ? <Popup
                        popup={popup}
                        handleClosePopup={handleClosePopup}
                        key={popup?.referencePath}
                    /> : <div key={key} className={styles.emptyPopup}></div>
                ))}
            </div>
        </Dialog>
    )
}