import { CART_BET_SETTING } from "../setting/settings";
import { reloadBetCartItems } from "@/core-nextv3/bet/bet.api";
import { create } from "zustand";

type reloadProps = {
  reloadCart: any,
  callReloadBetCartItems: any
}

export const useReloadBetCartItems = create<reloadProps>((set) => 
{
    return {
        reloadCart             : {},
        callReloadBetCartItems : async () => 
        {
            const data = await reloadBetCartItems(
                CART_BET_SETTING.merge({
                    dividend : {
                        referencePath : "default/levelTables/documents/7JkchxNheBXvCZddsUc2",
                    },
                })
            );
            set({
                reloadCart : data,
            });
        },
    };
});