import styles from "./styles.module.scss";
import { BiErrorCircle } from "react-icons/bi";

function ConfirmMessage({ message }: any) {
  return (
    <div className={styles.confirmMessage}>
      <BiErrorCircle />
      {message}
    </div>
  );
}

export default ConfirmMessage;
